import React from 'react'
import Navbar from '../components/navbar'
import Contactone from '../components/contact/contactone'

import Footer from '../components/footer'

export default function contact() {
  return (
    <div>
      <Navbar/>
        <Contactone/>
      
        <Footer/>
    </div>
  )
}
